const swiper = new Swiper(".carSwiper01", {
   	loop: true, // ループ有効
	lazy: true,
	slidesPerView: "auto",
	centeredSlides: true,
	speed: 6000, // ループの時間
	allowTouchMove: false, // スワイプ無効
	autoplay: {
		delay: 0,
		reverseDirection: true,
	},
});

const swiper02 = new Swiper(".carSwiper02", {
   	loop: true, // ループ有効
	slidesPerView: "auto",
	centeredSlides: true,
	speed: 6000, // ループの時間
	allowTouchMove: false, // スワイプ無効
	autoplay: {
		delay: 0, // 途切れなくループ
	},
});

jQuery(function($) {
	let mobileSwiper = null;
	function initSwiper() {
		if (window.innerWidth <= 768) {
			if (!mobileSwiper) {
				mobileSwiper = new Swiper('.js-moblieSlide', {
					slidesPerView: 1,
					spaceBetween: 26,
					centeredSlides: true,
					navigation: {
						nextEl: '.js-moblieSlide .swiper-button-next',
						prevEl: '.js-moblieSlide .swiper-button-prev',
					},
				});
			}
		} else {
			if (mobileSwiper) {
				mobileSwiper.destroy(true, true);
				mobileSwiper = null;
			}
		}
	}
	initSwiper();

	let spSwiper = null;
	function initSpSwiper() {
		if (window.innerWidth <= 568) {
			if (!spSwiper) {
				spSwiper = new Swiper('.js-spSlide', {
					slidesPerView: 1,
					spaceBetween: 26,
					centeredSlides: true,
					navigation: {
						nextEl: '.js-spSlide .swiper-button-next',
						prevEl: '.js-spSlide .swiper-button-prev',
					},
				});
			}
		} else {
			if (spSwiper) {
				spSwiper.destroy(true, true);
				spSwiper = null;
			}
		}
	}

	initSpSwiper();

	window.addEventListener('resize', initSwiper);
})